import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faStackOverflow } from "@fortawesome/free-brands-svg-icons/faStackOverflow";
import { faMedium } from "@fortawesome/free-brands-svg-icons/faMedium";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons/faEnvelope";
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: "https://github.com/nomanr",
    label: "Github",
    icon: faGithub,
  },
  {
    link: "https://www.linkedin.com/in/nomanrafique",
    label: "LinkedIn",
    icon: faLinkedinIn,
  },
  {
    link: "https://twitter.com/nomannnr",
    label: "Twitter",
    icon: faTwitter,
  },
  {
    link: "mailto:hello@nomanr.com",
    label: "Email",
    icon: faEnvelope,
  },
  {
    link: "https://stackoverflow.com/users/2991628/noman-rafique",
    label: "Stackoverflow",
    icon: faStackOverflow,
  },
  {
    link: "https://medium.com/@nomanr",
    label: "Medium",
    icon: faMedium,
  },
];

export default data;
