const routes = [
  {
    index: true,
    label: "Noman Rafique",
    path: "/",
  },
  {
    label: "About",
    path: "/about",
  },
  {
    label: "Resume",
    path: "/resume",
  },
  {
    label: "Work",
    path: "/work",
  },
  {
    label: "Contact",
    path: "/contact",
  },
];

export default routes;
